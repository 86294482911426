import { refDebounced } from "@vueuse/core";
import { computed, reactive, toRef, toRefs, watch } from "vue";

import type { TrackLike } from "@/types";

export class PlayerError extends Error {
  constructor(error: MediaError) {
    super(`Player Error: ${error.message} (${error.code})`);
  }
}

export function isPlayerError(error: unknown): error is PlayerError {
  return error instanceof PlayerError;
}

export type Player<Track extends TrackLike = TrackLike> = {
  state: "initial" | "waiting" | "playing" | "paused" | "error";
  playWhenReady: boolean;
  tracks: Track[];
  currentIndex: number;
  timeElapsed: number | undefined;
  duration: number | undefined;
  buffered: number | undefined;
  options: {
    autoAdvance: boolean;
    repeatMode: "none" | "queue" | "track";
  };
};

export function usePlayer<Track extends TrackLike = TrackLike>(
  options?: Partial<Player<Track>["options"]>,
) {
  const player = reactive<Player<Track>>({
    state: "initial",
    playWhenReady: false,
    tracks: [],
    currentIndex: 0,
    timeElapsed: undefined,
    duration: undefined,
    buffered: undefined,
    options: {
      autoAdvance: true,
      repeatMode: "none",
      ...options,
    },
  }) as Player<Track>;

  const state = refDebounced(toRef(player, "state"), 20);

  const position = computed(() =>
    player.timeElapsed !== undefined && player.duration !== undefined
      ? (player.timeElapsed / player.duration) * 100
      : undefined,
  );

  const timeRemaining = computed(() =>
    player.timeElapsed !== undefined && player.duration !== undefined
      ? player.duration - player.timeElapsed
      : undefined,
  );

  const currentTrack = computed(() => player.tracks[player.currentIndex]);

  const nextTrack = computed(
    () =>
      player.tracks[player.currentIndex + 1] ??
      (player.options.repeatMode === "queue" ? player.tracks[0] : undefined),
  );

  const prevTrack = computed(
    () =>
      player.tracks[player.currentIndex - 1] ??
      (player.options.repeatMode === "queue"
        ? player.tracks[player.tracks.length - 1]
        : undefined),
  );

  const audioElement = new Audio();

  const eventListeners: Partial<
    Record<
      keyof GlobalEventHandlersEventMap,
      (event: Event & { target: HTMLAudioElement }) => void
    >
  > = {
    loadstart: () => (player.state = "waiting"),
    waiting: () => (player.state = "waiting"),
    canplay: () => (player.state = "paused"),
    pause: () => (player.state = "paused"),
    playing: () => (player.state = "playing"),
    timeupdate: ({ target }) =>
      target.src && (player.timeElapsed = target.currentTime),
    durationchange: ({ target }) =>
      target.src && (player.duration = target.duration),
    ended: () =>
      player.options.autoAdvance &&
      (player.options.repeatMode === "track" ? play() : skipForward()),
    progress: ({ target }) =>
      target.buffered.length &&
      target.duration &&
      (player.buffered =
        (target.buffered.end(target.buffered.length - 1) / target.duration) *
        100),
    error: ({ target }) => {
      player.state = "error";

      if (target.error instanceof MediaError) {
        throw new PlayerError(target.error);
      }
    },
  };

  Object.entries(eventListeners).forEach(
    ([eventName, handler]) =>
      audioElement.addEventListener(eventName, (event) => {
        handler(event as Event & { target: HTMLAudioElement });
      }),
    { passive: true },
  );

  function load(track: Track) {
    audioElement.setAttribute("preload", "auto");
    audioElement.setAttribute("type", "audio/mpeg");
    audioElement.setAttribute("src", track.audioUrl);
    audioElement.load();
  }

  function unload() {
    audioElement.removeAttribute("src");
    audioElement.load();

    player.timeElapsed = undefined;
    player.duration = undefined;
    player.buffered = undefined;
  }

  async function play() {
    player.playWhenReady = true;

    try {
      await audioElement.play();
    } catch (error) {
      console.warn("Failed to start playback", error);
    }
  }

  function pause() {
    player.playWhenReady = false;

    audioElement.pause();
  }

  async function toggle() {
    audioElement.paused ? await play() : pause();
  }

  async function skipTo(index: number) {
    if (index === player.currentIndex) {
      seekTo(0);
      await play();
    } else if (player.tracks[index]) {
      player.currentIndex = index;
      player.playWhenReady = true;
    }
  }

  function skipForward() {
    if (!nextTrack.value) {
      return;
    }

    const nextIndex = player.tracks.indexOf(nextTrack.value);

    if (player.tracks[nextIndex]) {
      player.currentIndex = nextIndex;
      player.playWhenReady = true;
    }
  }

  function skipBackward() {
    if (!prevTrack.value) {
      return;
    }

    const prevIndex = player.tracks.indexOf(prevTrack.value);

    if (player.tracks[prevIndex]) {
      player.currentIndex = prevIndex;
      player.playWhenReady = true;
    }
  }

  function seekTo(time: number) {
    audioElement.currentTime = time;
  }

  function seekForward(offset = 10) {
    audioElement.currentTime = audioElement.currentTime + offset;
  }

  function seekBackward(offset = 10) {
    audioElement.currentTime = audioElement.currentTime - offset;
  }

  function toggleAutoAdvance() {
    player.options.autoAdvance = !player.options.autoAdvance;
  }

  function cycleRepeatMode() {
    const transitions = {
      none: "queue",
      queue: "track",
      track: "none",
    } as const;

    player.options.repeatMode = transitions[player.options.repeatMode];
  }

  function setTracklist(tracks: Track[], playWhenReady = true) {
    player.tracks = tracks;
    player.playWhenReady = playWhenReady;

    if (player.tracks[0]) {
      player.currentIndex = 0;
    }
  }

  function clearTracklist() {
    player.state = "initial";
    player.playWhenReady = false;
    player.tracks = [];
    player.currentIndex = 0;
  }

  watch(currentTrack, (track) => {
    unload();

    if (track) {
      load(track);

      if (player.playWhenReady) {
        void play();
      }
    }
  });

  return {
    ...toRefs(player),
    state,
    position,
    timeRemaining,
    currentTrack,
    nextTrack,
    prevTrack,
    toggleAutoAdvance,
    cycleRepeatMode,
    setTracklist,
    clearTracklist,
    play,
    pause,
    toggle,
    seekTo,
    seekForward,
    seekBackward,
    skipTo,
    skipForward,
    skipBackward,
  };
}
