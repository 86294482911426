import { acceptHMRUpdate, defineStore } from "pinia";
import { reactive, toRefs } from "vue";

import { useMediaSession, usePlayer } from "@/composables";
import { useUserStore } from "@/stores";
import type { Product, ProductTrack } from "@/types";

type State = {
  product: Product | undefined;
  isOpen: boolean;
};

export const usePlayerStore = defineStore(
  "player",
  () => {
    const state = reactive<State>({
      product: undefined,
      isOpen: false,
    });

    const player = usePlayer<ProductTrack>();

    useMediaSession(() => player.currentTrack.value, player);

    function initialize(product: Product) {
      if (!product.tracks.length) {
        throw new Error("Product has no supported tracks");
      }

      const userStore = useUserStore();

      player.setTracklist(
        product.tracks.map((track) => ({
          ...track,
          audioUrl: userStore.addAuthTokenToUrl(track.audioUrl),
        })),
      );

      state.product = product;
      state.isOpen = true;
    }

    function reset() {
      player.clearTracklist();

      state.product = undefined;
      state.isOpen = false;
    }

    return {
      ...toRefs(state),
      ...player,
      initialize,
      reset,
    };
  },
  {
    persist: {
      pick: ["product", "isOpen", "currentIndex", "tracks", "options"],
    },
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePlayerStore, import.meta.hot));
}
