export abstract class AbstractResponseError extends Error {}

export class UnauthorizedResponseError extends AbstractResponseError {
  constructor(message?: string) {
    super(message ?? "Unauthorized response");
  }
}

export class ValidationFailedResponseError extends AbstractResponseError {
  constructor(message?: string) {
    super(message ?? "Validation failed");
  }
}

export class UnexpectedResponseError extends AbstractResponseError {
  constructor(message?: string) {
    super(message ?? "Unexpected response");
  }
}

export function isResponseError(
  error: unknown,
): error is AbstractResponseError {
  return error instanceof AbstractResponseError;
}

export function isUnauthorizedResponseError(
  error: unknown,
): error is UnauthorizedResponseError {
  return error instanceof UnauthorizedResponseError;
}

export function isValidationFailedResponseError(
  error: unknown,
): error is ValidationFailedResponseError {
  return error instanceof ValidationFailedResponseError;
}

export function isUnexpectedResponseError(
  error: unknown,
): error is UnexpectedResponseError {
  return error instanceof UnexpectedResponseError;
}
