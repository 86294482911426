import { type MaybeRefOrGetter, toValue, watch } from "vue";

import type { TrackLike } from "@/types";

type ActionHandler = {
  play: () => void;
  pause: () => void;
  skipForward: () => void;
  skipBackward: () => void;
  seekForward: (offset?: number) => void;
  seekBackward: (offset?: number) => void;
  seekTo: (time: number) => void;
};

export function useMediaSession<Track extends TrackLike>(
  track: MaybeRefOrGetter<Track | undefined>,
  actionHandler: ActionHandler,
) {
  const { mediaSession } = navigator;
  const {
    play,
    pause,
    skipForward,
    skipBackward,
    seekForward,
    seekBackward,
    seekTo,
  } = actionHandler;

  mediaSession.setActionHandler("play", () => play());
  mediaSession.setActionHandler("pause", () => pause());
  mediaSession.setActionHandler("nexttrack", () => skipForward());
  mediaSession.setActionHandler("previoustrack", () => skipBackward());
  mediaSession.setActionHandler("seekforward", ({ seekOffset }) =>
    seekForward(seekOffset),
  );
  mediaSession.setActionHandler("seekbackward", ({ seekOffset }) =>
    seekBackward(seekOffset),
  );
  mediaSession.setActionHandler(
    "seekto",
    ({ seekTime }) => seekTime !== undefined && seekTo(seekTime),
  );

  watch(
    () => toValue(track),
    (newTrack) => {
      mediaSession.metadata = newTrack
        ? new MediaMetadata({
            title: newTrack.trackName,
            artist: newTrack.artistName,
            album: newTrack.collectionName,
            artwork: [{ src: newTrack.artworkUrl }],
          })
        : null;
    },
    { immediate: true },
  );
}
