import type {
  NavigationGuard,
  RouteLocationNormalizedGeneric,
  RouteLocationRaw,
} from "vue-router";

export type AccessMap = {
  guest: boolean;
  authenticated: boolean;
};

type Options<User> = {
  allowAccess: AccessMap;
  getUser: () => User;
  getRedirect: (
    user: User,
    to: RouteLocationNormalizedGeneric,
    from: RouteLocationNormalizedGeneric,
  ) => RouteLocationRaw;
};

export const createAuthGuard = <User>(
  options: Options<User>,
): NavigationGuard => {
  const { getRedirect, getUser } = options;

  return (to, from) => {
    const allowAccess = {
      ...options.allowAccess,
      ...to.meta.allowAccess,
    };

    const user = getUser();

    // If no user and guest access is not allowed, redirect
    if (!user && !allowAccess.guest) {
      return getRedirect(user, to, from);
    }

    // If user and authenticated access is not allowed, redirect
    if (user && !allowAccess.authenticated) {
      return getRedirect(user, to, from);
    }
  };
};
