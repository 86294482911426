import { resetStores } from "@packages/pinia-reset-stores";
import { useCancellablePromise } from "@packages/use-cancellable-promise";
import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, reactive, toRefs, watch } from "vue";

import { UserService } from "@/services";
import { useProductsStore } from "@/stores";
import type { Credential, User } from "@/types";

type State = {
  authToken: string | undefined;
  user: User | undefined;
};

export const useUserStore = defineStore(
  "user",
  () => {
    const state = reactive<State>({
      authToken: undefined,
      user: undefined,
    });

    const isAuthenticated = computed(() => {
      return state.authToken !== undefined;
    });

    const fullName = computed(() =>
      state.user
        ? [state.user.firstName, state.user.lastName].join(" ")
        : undefined,
    );

    const productsStore = useProductsStore();

    const loginRequest = useCancellablePromise(UserService.login);
    const resetPasswordRequest = useCancellablePromise(
      UserService.resetPassword,
    );

    async function login(credential: Credential) {
      const { authToken, user } = await loginRequest.execute(credential);

      state.authToken = authToken;
      state.user = user;
    }

    function logout() {
      resetStores();
    }

    async function resetPassword(email: string) {
      await resetPasswordRequest.execute(email);
    }

    function addAuthTokenToUrl(inputUrl: string) {
      if (!state.authToken) {
        throw new Error("No auth token available");
      }

      const url = new URL(inputUrl);

      url.searchParams.set("_token", state.authToken);

      return url.toString();
    }

    watch(
      isAuthenticated,
      (authenticated) => {
        if (authenticated) {
          void productsStore.load();
        }
      },
      { immediate: true },
    );

    return {
      ...toRefs(state),
      isAuthenticated,
      fullName,
      loginRequest,
      resetPasswordRequest,
      login,
      logout,
      resetPassword,
      addAuthTokenToUrl,
    };
  },
  {
    persist: {
      pick: ["authToken", "user"],
    },
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
