import {
  createApi,
  dateTransformer,
  isRequestError,
  isResponseError,
  isUnauthorizedResponseError,
  isUnexpectedResponseError,
  isValidationFailedResponseError,
  nullToUndefinedTransformer,
  snakeCaseRequestMiddleware,
} from "@packages/api";
import { createErrorHandler } from "@packages/error-handler";

import { useToasts } from "@/composables";
import { router } from "@/router";
import { useUserStore } from "@/stores";

import { authMiddleware } from "./auth.middleware";
import { camelCaseTransformer } from "./camel-case.transformer";

export const api = createApi({
  baseUrl: import.meta.env.PUBLIC_API_BASE_URL,
  middlewares: [authMiddleware, snakeCaseRequestMiddleware()],
  transformers: [
    camelCaseTransformer(),
    nullToUndefinedTransformer,
    dateTransformer((key) => ["created", "modified"].includes(key)),
  ],
});

export const errorHandler = createErrorHandler(async (error) => {
  const toasts = useToasts();

  if (isRequestError(error)) {
    toasts.showError(
      "Could not connect to the server. Check your internet connection, or try again later.",
    );
    return;
  }

  if (isUnauthorizedResponseError(error)) {
    const userStore = useUserStore();

    userStore.logout();

    await router.replace({ name: "login" });
    toasts.showError("Login has expired. Please log in again.");
    return;
  }

  if (isValidationFailedResponseError(error)) {
    toasts.showError("Could not process your request. Please try again later.");
    return;
  }

  if (isUnexpectedResponseError(error)) {
    toasts.showError("An unexpected error occurred. Please try again later.");
    return;
  }

  if (isResponseError(error)) {
    toasts.showError(error.message);
    return;
  }
});
