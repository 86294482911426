import { type PiniaPlugin, type Store } from "pinia";
import { clone } from "remeda";

const stores = new Set<Store>();

export const resetStoresPlugin: PiniaPlugin = ({ store }) => {
  const initialState = clone(store.$state);

  store.$reset = () => {
    store.$state = clone(initialState);
  };

  stores.add(store);
};

export function resetStores() {
  stores.forEach((store) => store.$reset());
}
