import { isPlatform } from "@ionic/vue";
import {
  createMemoryHistory,
  createRouter,
  createWebHistory,
} from "@ionic/vue-router";
import type { RouteLocationRaw } from "vue-router";

import { useUserStore } from "@/stores";

import { createAuthGuard, createTitleGuard } from "./guards";

/**
 * This is a hack to prevent native page swipe gesture on iOS PWA.
 * If there's no page history to swipe, what are you going to do, Apple...
 */
function createHistory(base?: string) {
  if (isPlatform("ios") && isPlatform("pwa")) {
    return createMemoryHistory(base);
  }

  return createWebHistory(base);
}

export const router = createRouter({
  history: createHistory(import.meta.env.BASE_URL),
  strict: true,
  routes: [
    {
      path: "/",
      name: "home",
      redirect: "/tabs",
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/LoginPage.vue"),
      meta: {
        title: "Log in",
        allowAccess: {
          guest: true,
          authenticated: false,
        },
      },
    },
    {
      path: "/autologin",
      name: "autologin",
      component: () => import("@/views/AutologinPage.vue"),
      meta: {
        title: "Logging in",
        allowAccess: {
          guest: true,
          authenticated: true,
        },
      },
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("@/views/ResetPasswordPage.vue"),
      meta: {
        title: "Reset Password",
        allowAccess: {
          guest: true,
          authenticated: false,
        },
      },
    },
    {
      path: "/tabs",
      component: () => import("@/views/TabsPage.vue"),
      children: [
        {
          path: "",
          name: "tabs",
          redirect: "/tabs/library",
        },
        {
          path: "library",
          name: "library",
          component: () => import("@/views/LibraryPage.vue"),
          meta: {
            title: "Library",
          },
        },
        {
          path: "catalog",
          name: "catalog",
          component: () => import("@/views/CatalogPage.vue"),
          meta: {
            title: "Catalog",
          },
        },
        {
          path: "catalog/:productId",
          props: true,
          name: "catalog-product-detail",
          component: () => import("@/views/ProductDetailPage.vue"),
          meta: {
            title: "Catalog",
          },
        },
        {
          path: "settings",
          name: "settings",
          component: () => import("@/views/SettingsPage.vue"),
          meta: {
            title: "Settings",
          },
        },
      ],
    },
    {
      path: "/:notFound(.*)",
      redirect: "/",
    },
  ],
});

export function resolveRoute(route: RouteLocationRaw) {
  return router.resolve(route).href;
}

const authGuard = createAuthGuard({
  allowAccess: {
    guest: false,
    authenticated: true,
  },
  getUser: () => {
    const userStore = useUserStore();

    return userStore.user;
  },
  getRedirect: (user, to) => {
    if (user) {
      return {
        name: "library",
        replace: true,
      };
    }

    return {
      name: "login",
      query: { redirect: to.fullPath },
      replace: true,
    };
  },
});

router.beforeEach(createTitleGuard(import.meta.env.PUBLIC_APP_NAME));
router.beforeEach(authGuard);
