import { alertController } from "@ionic/vue";
import { useCancellablePromise } from "@packages/use-cancellable-promise";
import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, reactive, toRefs } from "vue";

import { ProductsService } from "@/services";
import { usePlayerStore } from "@/stores";
import type { Product } from "@/types";

type State = {
  products: Record<Product["id"], Product>;
  ids: Record<"catalog" | "library", Product["id"][]>;
};

export const useProductsStore = defineStore("products", () => {
  const state = reactive<State>({
    products: {},
    ids: { catalog: [], library: [] },
  });

  const playerStore = usePlayerStore();

  const loadRequest = useCancellablePromise(
    ProductsService.getCatalogAndLibrary,
  );

  const catalog = computed(() => {
    return state.ids.catalog
      .map((id) => state.products[id])
      .filter((product) => product !== undefined);
  });

  const library = computed(() => {
    return state.ids.library
      .map((id) => state.products[id])
      .filter((product) => product !== undefined);
  });

  async function load() {
    const products = await loadRequest.execute();

    state.products = [...products.catalog, ...products.library].reduce(
      (combined, product) => ({ ...combined, [product.id]: product }),
      {},
    );

    state.ids.catalog = products.catalog.map((product) => product.id);
    state.ids.library = products.library.map((product) => product.id);
  }

  function get(id: Product["id"]) {
    return state.products[id];
  }

  function isOwned(id: Product["id"]) {
    return state.ids.library.includes(id);
  }

  async function play(id: Product["id"]) {
    const product = get(id);

    if (!product) {
      throw new Error(`Product with id ${id} not found`);
    }

    if (!product.canPlay) {
      await showPlayUnsupportedDialog();
      return;
    }

    const isAlreadyPlaying =
      playerStore.product && playerStore.product.id === product.id;

    if (isAlreadyPlaying) {
      playerStore.isOpen = true;
      return;
    }

    const shouldConfirm = playerStore.product && playerStore.playWhenReady;

    if (shouldConfirm) {
      await showConfirmPlayDialog(product);
      return;
    }

    playerStore.initialize(product);
  }

  async function showPlayUnsupportedDialog() {
    const dialog = await alertController.create({
      header: "Unsupported audiobook",
      message: "This audiobook is not supported in the app yet.",
      buttons: ["OK"],
    });

    await dialog.present();
  }

  async function showConfirmPlayDialog(product: Product) {
    const dialog = await alertController.create({
      header: "Switch audiobook",
      message:
        "Playback of the current audiobook will stop. Do you want to continue?",
      buttons: [
        { text: "Cancel", role: "cancel" },
        {
          text: "Continue",
          handler: () => playerStore.initialize(product),
        },
      ],
    });

    await dialog.present();
  }

  return {
    ...toRefs(state),
    loadRequest,
    catalog,
    library,
    load,
    get,
    isOwned,
    play,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProductsStore, import.meta.hot));
}
