import {
  buildCancellablePromise,
  CancellablePromise,
} from "real-cancellable-promise";
import { z } from "zod";

import { api } from "@/api";
import { type Product, productSchema } from "@/types";

export function getCatalog() {
  return api.get<Product[]>("/v2/products", {
    schema: z
      .object({ data: z.array(productSchema) })
      .transform((response) => response.data),
  });
}

export function getLibrary() {
  return api.get<Product[]>("/v2/products/library", {
    schema: z
      .object({ data: z.array(productSchema) })
      .transform((response) => response.data),
  });
}

export function getCatalogAndLibrary() {
  return buildCancellablePromise(async (capture) => {
    const [catalog, library] = await capture(
      CancellablePromise.all([getCatalog(), getLibrary()]),
    );

    return { catalog, library };
  });
}
