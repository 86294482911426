import { toastController, type ToastOptions } from "@ionic/vue";
import { reactive } from "vue";

type State = {
  toasts: ToastOptions[];
};

const state = reactive<State>({
  toasts: [],
});

export function useToasts() {
  function getPositionAnchor() {
    const page =
      document.querySelector<HTMLElement>(
        "ion-modal .ion-page:not(.ion-page-hidden)",
      ) ??
      document.querySelector<HTMLElement>(
        "ion-app .ion-page:not(.ion-page-hidden)",
      );

    if (!page) {
      return undefined;
    }

    return (
      page.querySelector<HTMLElement>(".mini-player") ??
      page.querySelector<HTMLIonFooterElement>("ion-footer") ??
      page.querySelector<HTMLIonTabBarElement>("ion-tab-bar") ??
      undefined
    );
  }

  function isDuplicate(toast: ToastOptions) {
    return state.toasts.some(
      (queuedToast) => queuedToast.message === toast.message,
    );
  }

  function addToast(toast: ToastOptions) {
    if (isDuplicate(toast)) {
      return;
    }

    setTimeout(() => {
      state.toasts.push({
        duration: 5000,
        position: "bottom",
        positionAnchor: getPositionAnchor(),
        ...toast,
      });

      if (state.toasts.length === 1) {
        void process();
      }
    }, 280);
  }

  function showInfo(
    message: string,
    options?: Omit<ToastOptions, "color" | "message">,
  ) {
    addToast({ ...options, message, color: "primary" });
  }

  function showSuccess(
    message: string,
    options?: Omit<ToastOptions, "color" | "message">,
  ) {
    addToast({ ...options, message, color: "success" });
  }

  function showError(
    message: string,
    options?: Omit<ToastOptions, "color" | "message">,
  ) {
    addToast({ ...options, message, color: "danger" });
  }

  async function process() {
    const toast = state.toasts[0];

    if (!toast) {
      return;
    }

    const controller = await toastController.create(toast);

    await controller.present();
    await controller.onDidDismiss();

    state.toasts.shift();

    if (state.toasts.length > 0) {
      await process();
    }
  }

  return {
    showInfo,
    showSuccess,
    showError,
  };
}
