import { resetStoresPlugin } from "@packages/pinia-reset-stores";
import { createPinia } from "pinia";
import { createPersistedState } from "pinia-plugin-persistedstate";

export const pinia = createPinia()
  .use(resetStoresPlugin)
  .use(createPersistedState());

export * from "./checkout.store";
export * from "./player.store";
export * from "./products.store";
export * from "./user.store";
