import { type CancellablePromise } from "real-cancellable-promise";
import { computed, reactive, toRefs } from "vue";

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type CancellablePromiseFn = (...args: any[]) => CancellablePromise<any>;

type State<T extends CancellablePromiseFn> = {
  status: "initial" | "pending" | "fulfilled" | "rejected";
  fulfilledCount: number;
  rejectedCount: number;
  data: Awaited<ReturnType<T>> | undefined;
  error: unknown;
};

export function useCancellablePromise<T extends CancellablePromiseFn>(fn: T) {
  let latestPromise: CancellablePromise<ReturnType<T>> | undefined = undefined;

  const state = reactive<State<T>>({
    status: "initial",
    fulfilledCount: 0,
    rejectedCount: 0,
    data: undefined,
    error: undefined,
  });

  const isInitial = computed(() => state.status === "initial");
  const isPending = computed(() => state.status === "pending");
  const isFirstPending = computed(
    () =>
      state.status === "pending" &&
      state.fulfilledCount === 0 &&
      state.rejectedCount === 0,
  );
  const isFulfilled = computed(() => state.status === "fulfilled");
  const isRejected = computed(() => state.status === "rejected");

  /**
   * Executes the provided function and updates the state accordingly.
   */
  async function execute(
    ...args: Parameters<T>
  ): Promise<Awaited<ReturnType<T>>> {
    if (latestPromise) {
      latestPromise.cancel();
    }

    const currentPromise = fn(...args);

    latestPromise = currentPromise;

    try {
      state.status = "pending";
      const result = await currentPromise;

      state.status = "fulfilled";
      state.fulfilledCount++;
      state.data = result;
      state.error = undefined;

      return result;
    } catch (error) {
      if (latestPromise === currentPromise) {
        state.status = "rejected";
        state.rejectedCount++;
        state.error = error;
      }

      throw error;
    }
  }

  /**
   * Cancels the latest promise.
   */
  function cancel() {
    if (latestPromise) {
      latestPromise.cancel();
    }
  }

  return {
    ...toRefs(state),
    isInitial,
    isPending,
    isFirstPending,
    isFulfilled,
    isRejected,
    execute,
    cancel,
  };
}
